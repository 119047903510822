import { default as Axios, default as axios } from "axios";
import CryptoJS from "crypto-js";
import React from "react";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { generateEncryptJwt, generateJwtToken } from "./generateEncryptJwt";
let toastConfigure = {
  hideProgressBar: true,
  autoClose: 600000,
  position: "top-right",
};

let key = CryptoJS.enc.Base64.parse("#AyIruSARTISEncDeckEy#");
let iv = CryptoJS.enc.Base64.parse("#AyIruSARTISEncDeciV#");
let logout = 1;

const userKey = "logaccess";
const mobileKey = "mobaccess";
const validtoken = "validtoken";
export const pairSymbol = "-";
const headers = async (accesstoken, endpoint) => {
  let encryptToken = generateEncryptJwt();
  // let NewtokenGenerate = await generateJwtToken(endpoint, encryptToken); // Await here
  let NewtokenGenerate = "";
  let token = localStorage.getItem(userKey);
  let vtoken = localStorage.getItem(validtoken);
  const axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "true",
      "Content-Type": "application/json;charset=UTF-8",
      "device-type": "web",
      "content-vtieer-org": encryptToken,
      "content-vtieer-head": NewtokenGenerate,
    },
  };
  let auth = token ? token : accesstoken;
  if (auth) {
    axiosConfig.headers["Authorization"] = `Bearer ${auth}`;
  }
  return axiosConfig;
};

const Imageheaders = (accesstoken) => {
  let token = localStorage.getItem(userKey);
  let vtoken = localStorage.getItem(validtoken);
  const axiosConfig = {
    headers: {},
  };
  let auth = token ? token : accesstoken;
  if (accesstoken || token) {
    axiosConfig.headers["Authorization"] = `Bearer ${auth}`;
  }
  if (vtoken) {
    axiosConfig.headers["validtoken"] = vtoken;
  }
  return axiosConfig;
};

export const clearAll = (url) => {
  setTimeout(() => {
    localStorage.removeItem(userKey);
    localStorage.clear();
    window.location.href = "/oauth/login";
  }, 1000);
};

export const socketConfigs = {
  transports: ["websocket"],
  reconnection: true, // enable auto-reconnect
  reconnectionAttempts: 10, // max attempts before giving up
  timeout: 5000, // time to wait between attempts (in ms)
};

export const pusherKey = {
  apiKey: "f8e1c82a03aeafa31357",
  clusterkey: "ap2",
};

export const baseUrl = (type) => {
  if (type == "demo") {
    return "https://kpkparkback.koinpark.com/";
  } else if (type == "newBot") {
    return "https://kpkkpkbaknd.kpkpark.com/";
  } else if (type == "stake") {
    return "https://kpkkpkbaknd.kpkpark.com/";
  } else if (type == "inves") {
    return "https://kpkkpkbaknd.kpkpark.com/";
  } else {
    return "https://kpkkpkbaknd.kpkpark.com/";
  }
};

const Helper = {
  PostData: async (type, url, payload, toasts = true, accesstoken) => {
    if (url === "dashboard/profile") {
      toasts = false;
    }
    const baseUrlEndpoint = baseUrl(type) + url;
    const headerConfig = await headers(accesstoken, baseUrlEndpoint);
    return axios
      .post(baseUrl(type) + url, payload, headerConfig)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let data = res.data;
          if (!data.message) {
            // alert(url, 'empty toast')
            toasts = false;
          }

          if ([true, 1, "1", 200].includes(data.status)) {
            if (url === "dashboard/profile") {
              if (!data.data.email) {
                clearAll(url);
              }
            }
            if (toasts) {
              NotificationManager.success(data.message);
            }
            return { ...data, status: true };
          } else if (data.status === 5) {
            if (logout == 1) {
              NotificationManager.error(data.message);
            }

            logout = 2;
            clearAll(url);
          } else if (data.status === 6) {
            window.location.href = "/maintanence";
          } else {
            if (toasts) {
              NotificationManager.error(data.message);
            }
          }
          return { ...data, status: false };
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          NotificationManager.error("Session Timed Out!");
          clearAll(url);
          // return
        }
        if (e.response && e.response.status > 500) {
          NotificationManager.error("Network Error!");
        }
        return { status: false, url };
      });
  },

  GetData: async (type, url, accesstoken) => {
    //access token come from redux
    const baseUrlEndpoint = baseUrl(type) + url;
    const headerConfig = await headers(accesstoken, baseUrlEndpoint);
    return Axios.get(baseUrl(type) + url, headerConfig)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if (data.status === 5) {
            clearAll(url);
          } else if (data.status === 6) {
            window.location.href = "/maintanence";
          }
          return data;
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          NotificationManager.error("Session Timed Out!");
          clearAll(url);
        }
        if (e.response && e.response.status > 500) {
          NotificationManager.error("Network Error!");
        }

        return { status: false, url };
      });
  },
  // Excel Report
  excelReport: async (url, type, payload, toast = false,filename, accesstoken) => {
    try {
      const baseUrlEndpoint = baseUrl(type) + url;
      const headerConfig = await headers(accesstoken, baseUrlEndpoint);
      const response = await fetch(baseUrlEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("logaccess")}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.status == 200) {
        const blob = await response.blob();
        const urlBlob = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlBlob;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(urlBlob);
      } else {
        NotificationManager.error("Network error try again later");
      }
    } catch {
      NotificationManager.error("Error downloading the file");
    }
  },
  //   Image Upload Formdata

  PostImageData: (type, url, payload, toasts = false,accesstoken) =>
    axios
      .post(baseUrl(type) + url, payload, Imageheaders(accesstoken))
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if ([true, 1, 200].includes(data.status)) {
            if (toasts) {
              NotificationManager.success(data.message);
            }
            return { ...data, status: true };
          } else if (data.status === 401) {
            NotificationManager.error(data.message);
            clearAll();
          } else {
            if (toasts) {
              NotificationManager.error(data.message);
            }
          }
          return { ...data, status: false };
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          NotificationManager.error("Session Timed Out!");
          clearAll();
          // return
        }
        if (e.response && e.response.status > 500) {
          NotificationManager.error("Network Error!");
        }
        return { status: false, url };
      }),
  formatDated: function (date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var dates = date.getDate();
    var months = date.getMonth() + 1;
    var years = date.getFullYear();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    dates = dates < 10 ? "0" + dates : dates;
    months = months < 10 ? "0" + months : months;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return dates + "/" + months + "/" + years + " " + strTime;
  },
  formatDated1: function (date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var dates = date.getDate();
    var months = date.getMonth() + 1;
    var years = date.getFullYear();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    dates = dates < 10 ? "0" + dates : dates;
    months = months < 10 ? "0" + months : months;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return years + "-" + months + "-" + dates;
  },

  roundValues: function (num, precision) {
    if (typeof num != undefined && typeof num != "undefined") {
      if (num) {
        if (num.toString().indexOf("e") > -1) {
          num = parseFloat(num).toFixed(precision);
        }
        var num1 = num.toString().split(".");
        var num2 = num1[0];
        if (num1.length === 2) {
          num2 = num2 + "." + num1[1].substring(0, precision);
        }
        return parseFloat(num2).toFixed(precision);
      } else {
        return (0).toFixed(precision);
      }
    } else {
      return num;
    }
  },
  formatAddress: (address, padding = 4) => {
    if (address && address.length > 0) {
      return `${address.substr(0, padding)}…${address.substr(-3)}`;
    } else {
      return address;
    }
  },
  // formatEmailAddress: (address, padding = 5) => {
  //   if (address && address.length > 0) {
  //     return `${address.substr(0, padding)}***${address.substr(-8)}`;
  //   } else {
  //     return address;
  //   }
  // },
  formatEmailAddress: (address, padding = 5) => {
    // let address = "a@test.com"
    if (address && address.length > 0) {
      let splited = address && address.split(".");
      if (splited && splited[0].length > 7) {
        return `${address.substring(0, 4)}***${address.substring(
          address.length - 8
        )}`;
      } else {
        return `${address.substring(0, 3)} ***${address.substring(
          address.length - 4
        )}`;
      }
    } else {
      return address;
    }
  },
  formatRefLink: (address, padding = 12) => {
    if (address && address.length > 0) {
      return `${address.substr(0, padding)}...${address.substr(-8)}`;
    } else {
      return address;
    }
  },
  formatPhone: (phone, padding = 3) => {
    if (phone && phone.length > 0) {
      return `${phone.substr(0, padding)}****${phone.substr(-3)}`;
    } else {
      return phone;
    }
  },

  formatValue: (value, returnZero = true, style) => {
    if (value) {
      let value_length = 9;
      let str_value = String(value);
      let str_value_length = str_value.length;
      value = +value;
      if (str_value.includes(".")) {
        if (str_value.slice(0, 8) != "0.000000") {
          let split_str_value = str_value.split(".");
          if (split_str_value[0].length > 2) {
            return value.toPrecision(8);
          } else if (split_str_value[1].length > 6) {
            return value.toFixed(6);
          } else {
            return value.toFixed(6);
          }
        } else {
          let first_value_index = [...str_value].findIndex(
            (val, ind) => ind > 7 && 0 < val //skipping 1st 0.000000 value
          );
          let after_zeros = str_value.slice(
            first_value_index,
            first_value_index + 3
          );
          return (
            <div class={style ? "tool mr-5" : "tool"} data-tip={str_value}>
              0.0...{after_zeros}
            </div>
          );
        }
      } else {
        if (str_value_length >= value_length) {
          return value;
        } else {
          return value.toPrecision(8);
        }
      }
    } else {
      return returnZero === true ? (0).toFixed(6) : "-";
    }
  },
  copyText: (text, cb = () => {}, index, setCopy) => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(text).then(() => cb(index, setCopy));
    } else if (document.queryCommandSupported("copy")) {
      const ele = document.createElement("textarea");
      ele.value = text;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand("copy");
      document.body.removeChild(ele);
      cb(index, setCopy);
    }
  },
  pasteText: (cb = () => {}) => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.readText &&
        navigator.clipboard.readText().then((cliptext) => cb(cliptext));
    }
  },
  toasts: (text, status, timeout = 5000) => {
    if (status) {
      NotificationManager.success(text);
    } else {
      NotificationManager.error(text, "", timeout);
    }
  },
  removeEPower: (x, decimal = 18) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
      var rightDecimal = parseInt(x.toString().split(".")[1]);

      if (rightDecimal > +decimal) {
        x = Number.parseFloat(x).toFixed(decimal);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }

      var rightDecimal = parseInt(x.toString().split(".")[1]);

      if (rightDecimal > +decimal) {
        x = Number.parseFloat(x).toFixed(decimal);
      }
    }

    return x;
  },
  encryptData: (value) => {
    if (value) {
      let cipher = CryptoJS.AES.encrypt(value, key, { iv }).toString();
      return cipher;
    } else {
      return "";
    }
  },

  decryptData: (value) => {
    if (value) {
      let decipher = CryptoJS.AES.decrypt(value, key, { iv });
      let decrypt_val = decipher.toString(CryptoJS.enc.Utf8);
      return decrypt_val;
    } else {
      return "";
    }
  },
};

export default Helper;
