import React from 'react'

import footerlogo from '../../assets/images/kp-new/footerlogo.svg'
import facebookIcon from '../../assets/images/facebook.svg'
import instagramIcon from '../../assets/images/instagram.svg'
import twitterIcon from '../../assets/images/twitter1.svg'
import youtubeIcon from '../../assets/images/youtube.svg'
import linkedinIcon from '../../assets/images/linkedin.svg'
import teleFrameIcon from '../../assets/images/teleFrame.svg'
import { Link } from 'react-router-dom'

import './footer.scss'

function Footer() {
	return (
		<>
			<footer>
				<div className='footerSec'>
					<div className='container'>
						<div className='row justify-content-center'>
							<div className='col-md-10 mt-5'>
								<div className='footerTitle text-center mb-4'>
									<img
										src={footerlogo}
										alt='footerlogo'
										width="160"
										height="160"
									/>
								</div>
								<div className='footerlist'>
									<Link className='footerlink' to='/aboutus'>
										About Us
									</Link>
									<Link className='footerlink' to='/faq'>
										FAQ
									</Link>
									<Link className='footerlink' to='/user/support'>
										Support
									</Link>
									<Link className='footerlink' to='/terms'>
										Terms & Conditions
									</Link>
									<Link className='footerlink' to='/privacy'>
										Privacy Policy
									</Link>
								</div>
							</div>

							<div className='col-12'>
								<div className='Footersocials'>
									<div>
										<Link
											to='https://www.facebook.com/koinpark'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img src={facebookIcon} alt='facebookIcon' width="16" height="16"/>
										</Link>
									</div>
									<div>
										<Link
											to='https://www.instagram.com/koinparkinfo/'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img src={instagramIcon} alt='instagramIcon' width="16" height="16"/>
										</Link>
									</div>
									<div>
										<Link
											to='https://x.com/KoinparkExc'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img
												src={twitterIcon}
												alt='twitterIcon'
												width="17" height="17"
											/>
										</Link>
									</div>
									<div>
										<Link
											to='https://www.youtube.com/@koinparkInfo'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img src={youtubeIcon} alt='youtubeIcon' width="18" height="18"/>
										</Link>
									</div>
									<div>
										<Link
											to='https://www.linkedin.com/company/koinpark'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img src={linkedinIcon} alt='linkedinIcon' width="18" height="18"/>
										</Link>
									</div>
									<div>
										<Link
											to='https://t.me/samcrypto9'
											target='_blank'
											className='socialmediaLinkbtn'
										>
											<img src={teleFrameIcon} alt='teleFrameIcon' width="16" height="16"/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='Footer-bottom'>
					<div className='container'>
						<div className='row'>
							<div className='col-12 secondfooter pt-3 pb-3'>
								<div className='text-center mx-auto text-light'>
									&copy; 2023 - KPK PARK - All Rights Reserved.
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
