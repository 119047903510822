import React, { useState } from 'react'
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import MeetingReports from './MeetingReports';
import TodoReports from './TodoReports';
import LeadReports from './LeadReports';
import GoalReports from './GoalReports';

const ReportsNavIndex = () => {
    const [activeTab, setActiveTab] = useState("1");

    //DashBoard Main Tab Start
    const toggle = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="">
            <div className="dashboard-navs">
                <div className="row px-2">
                    <div className="col-lg-12">
                        <Nav tabs className="cursor-pointer" style={{ gap: "30px" }}>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                        toggle("1");
                                    }}
                                >
                                    Meeting Report
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                        toggle("2");
                                    }}
                                >
                                    Todo Report
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === "3",
                                    })}
                                    onClick={() => {
                                        toggle("3");
                                    }}
                                >
                                    Leads Report
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: activeTab === "4",
                                    })}
                                    onClick={() => {
                                        toggle("4");
                                    }}
                                >
                                    Goals Report
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={"1"}>
                                {activeTab == "1" && <MeetingReports />}
                            </TabPane>
                            <TabPane tabId={"2"}>
                                {activeTab == "2" && <TodoReports />}
                            </TabPane>
                            <TabPane tabId={"3"}>
                                {activeTab == "3" && <LeadReports />}
                            </TabPane>
                            <TabPane tabId={"4"}>
                                {activeTab == "4" && <GoalReports />}
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsNavIndex