import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../../Helper/Helper";
import { TableLoader } from "../../../component/Loaders";
import DatePickerReuse2 from "../../../component/Reusable/DatePicker2Reuse";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import SearchReuse2 from "../../../component/Reusable/searchReuse2";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
const LeadReports = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [Loading, setLoading] = useState(false);
  const [MeetingReport, setMeetinReport] = useState({
    data: [],
    count: 0,
  });
  const [filters, setFilter] = useState({
    status: "all",
    end_date: "",
    search_by: "all",
    start_date: "",
    pageNo: 0,
    pageSize: 10,
    type: "team",
  });

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = async () => {
    setLoading(true);
    Helper.PostData("", "leads/lead_list", filters, false)
      .then((res) => {
        if (res.status) {
          setMeetinReport({
            data: res.data.result,
            count: res.data.total_count,
          });
        } else {
          setMeetinReport({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      width: "100px",
    },

    {
      name: "User Name",
      selector: (row, index) => row.username,
      sortable: true,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.username}</td>
      ),
    },
    {
      name: "Mobile",
      sortable: true,
      selector: (row, index) => row.mobile_no,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.mobile_no}</td>
      ),
    },
    {
      name: "Location",
      sortable: true,
      selector: (row, index) => row.location,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.location}</td>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row, index) => row.status,
      cell: (row, index) => (
        <td className="text-center wwwdsafsdfdsfs">
          {row.status == 0 ? (
            <span className="text-warning">Pending</span>
          ) : row.status == 1 ? (
            <span className="text-success">Completed</span>
          ) : (
            <span className="text-danger">Rejected</span>
          )}
        </td>
      ),
    },
  ];

  const exportFile = useCallback(() => {
    const headers = {
      created_at: "Date",
      Name: "User Name",
      emailId: "Email",
      mobile: "Mobile Number",
      location: "Location",
      address: "Address",
      status: "Status",
    };

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Add header row
    worksheet.addRow([
      headers.created_at,
      headers.Name,
      headers.emailId,
      headers.mobile,
      headers.location,
      headers.address,
      headers.status,
    ]);

    // Style the header row
    worksheet.getRow(1).font = { bold: true };

    // Add data rows
    MeetingReport.data.forEach((item) => {
      worksheet.addRow([
        moment(item.created_at).format("YYYY-MM-DD") || "-",
        item.username || "-",
        item.emailId || "-",
        item.mobile_no || "-",
        item.location || "-",
        item.address || "-",
        item.status === 0
          ? "Pending"
          : item.status === 1
          ? "Completed"
          : "Rejected",
      ]);
    });

    // Write to file as a blob
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "LeadsReport.xlsx");
      })
      .catch((error) => {
        console.error("Error generating file:", error);
      });
  }, [MeetingReport.data]);
  return (
    <Fragment>
      <div className="trans-body mt-5">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Body">
              <div className="tbl-header-items">
                <div className="showentries-LeftCnt">
                  <div className="row inr-cnt">
                    <div className="col-lg-2">
                      <select
                        className="form-control"
                        onChange={({ target: { value } }) => {
                          setFilter((prev) => ({
                            ...prev,
                            type: value,
                          }));
                        }}
                      >
                        <option value="team">Team</option>
                        <option value="self">Self</option>
                      </select>
                    </div>
                    <div className="col-lg-2">
                      <select
                        className="form-control"
                        onChange={({ target: { value } }) => {
                          setFilter((prev) => ({
                            ...prev,
                            status: value == "all" ? value : +value,
                          }));
                        }}
                      >
                        <option value="all">All</option>
                        <option value="0">Pending</option>
                        <option value="1">Completed</option>
                        <option value="2">Rejected</option>
                      </select>
                    </div>
                    <DatePickerReuse2
                      setFilters={setFilter}
                      className="col-lg-2"
                    />
                    <SearchReuse2
                      className={"col-lg-2"}
                      placeholder={"Search By User Name"}
                      setFilters={setFilter}
                      initialPageData={initialPageData}
                      filterKey="search_by"
                      type="lead"
                    />
                    {MeetingReport?.data?.length == 0 ? (
                      <div className="col-lg-2 ">
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </div>
                    ) : (
                      <div
                        className="col-lg-2 cursor-pointer"
                        onClick={() => exportFile()}
                      >
                        {/* <CSVLink> */}
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                        {/* </CSVLink> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="cmntbl mt-4">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    className="text-center mt-3"
                    data={MeetingReport.data}
                    paginationTotalRows={MeetingReport.count}
                    customStyles={dataTableConfigs}
                    pagination={true}
                    paginationServer
                    onChangePage={(pageNo) => {
                      setFilter((prev) => ({
                        ...prev,
                        pageNo: pageNo - 1,
                      }));
                    }}
                    progressPending={Loading}
                    progressComponent={<TableLoader />}
                    noDataComponent={<NoDataComponent />}
                    onChangeRowsPerPage={(pageSize) => {
                      setFilter((prev) => ({ ...prev, pageSize }));
                    }}
                    persistTableHead={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LeadReports;
