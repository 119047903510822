import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../../Helper/Helper";
import { TableLoader } from "../../../component/Loaders";
import DatePickerReuse2 from "../../../component/Reusable/DatePicker2Reuse";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const MeetingReports = () => {
  const [Loading, setLoading] = useState(false);
  const [MeetingReport, setMeetinReport] = useState({
    data: [],
    count: 0,
  });
  console.log("MeetingReport: ", MeetingReport);
  const [userDetails, setUserDetsils] = useState([]);
  const [BatchList, setBatchList] = useState([]);
  const [filters, setFilter] = useState({
    pageSize: 10,
    pageNo: 0,
    viewType: 1, // 0-initial no data 1-my-records 2-myteammembers records,
    start_date: "",
    end_date: "",
    optionType: "all", //(all,single,batch)
    subOption: "", //(all=>""),  (single  => userrandomid ) , (batch => rank )
  });
  console.log("filters: ", filters);

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  useEffect(() => {
    GetUserRefDetails();
    Helper.GetData("", "stake/batchList", false).then((res) => {
      if (res.status) {
        setBatchList(res.data);
      }
    });
  }, []);

  const GetUserRefDetails = () => {
    Helper.GetData("flow-new", "user_crm/direct_ref").then((Res) => {
      if (Res.status) {
        setUserDetsils(Res.data);
      }
    });
  };
  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("", "bopMeeting/bopMeetingReportHistory", filters, false)
      .then((res) => {
        if (res.status) {
          setMeetinReport({
            data: res.data.results,
            count: res.data.count,
          });
        } else {
          setMeetinReport({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Attend User",
      sortable: true,
      selector: (row) => row.attendance_users,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.bop_meeting_status,
      cell: (row) => {
        if (row.bop_meeting_status == 0) {
          return <div className=" badge text-warn">Scheduled</div>;
        } else if (row.bop_meeting_status == 1) {
          return <div className="badge bg-success">Completed</div>;
        } else {
          return <div className="badge bg-danger">Rejected</div>;
        }
      },
    },
    {
      name: "Meeting Start",
      sortable: true,
      selector: (row, index) => row.start_date,
      cell: (row, index) => (
        <td>{moment(row.start_date).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
    {
      name: "Meeting End",
      sortable: true,
      selector: (row, index) => row.end_date,
      cell: (row, index) => (
        <td>{moment(row.end_date).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
  ];

  const exportFile = useCallback(() => {
    const headers = {
      title: "Title",
      location: "Location",
      attendUser: "Attend user",
      status: "Status",
      start_at: "Meeting Start Date",
      end_at: "Meeting End Date",
    };

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Add header row
    worksheet.addRow([
      headers.title,
      headers.location,
      headers.attendUser,
      headers.status,
      headers.start_at,
      headers.end_at,
    ]);

    // Style the header row
    worksheet.getRow(1).font = { bold: true };

    // Add data rows
    MeetingReport.data.forEach((item) => {
      worksheet.addRow([
        item.title || "-",
        item.location || "-",
        item.attendance_users || "-",
        item.bop_meeting_status === 0
          ? "Scheduled"
          : item.bop_meeting_status === 1
          ? "Completed"
          : "Rejected",
        moment(item.start_date).format("YYYY-MM-DD hh:mm:ss A") || "-",
        moment(item.end_date).format("YYYY-MM-DD hh:mm:ss A") || "-",
      ]);
    });

    // Write to file as a blob
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "MeetingReport.xlsx");
      })
      .catch((error) => {
        console.error("Error generating file:", error);
      });
  }, [MeetingReport.data]);
  return (
    <Fragment>
      <div className="trans-body mt-5">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Body">
              <div className="tbl-header-items">
                <div className="showentries-LeftCnt">
                  <div className="row inr-cnt">
                    <div
                      className={(() => {
                        if (filters.viewType == 2) {
                          if (
                            filters.optionType == "single" ||
                            filters.optionType == "batch"
                          ) {
                            return "col-lg-2";
                          } else {
                            return "col-lg-3";
                          }
                        } else {
                          return "col-lg-4";
                        }
                      })()}
                    >
                      <select
                        className="form-control"
                        onChange={({ target: { value } }) => {
                          setFilter((prev) => ({
                            ...prev,
                            viewType: +value,
                            optionType:
                              +value === 1
                                ? ""
                                : +value === 2
                                ? "all"
                                : prev.optionType,
                            subOption: +value === 1 ? "" : prev.subOption,
                          }));
                        }}
                      >
                        <option value="1">My Records</option>
                        <option value="2">My Team Members Records</option>
                      </select>
                    </div>
                    {filters.viewType == 2 && (
                      <div
                        className={
                          filters.optionType == "single" ||
                          filters.optionType == "batch"
                            ? `col-lg-2`
                            : "col-lg-3"
                        }
                      >
                        <select
                          className="form-control"
                          onChange={({ target: { value } }) => {
                            setFilter((prev) => ({
                              ...prev,
                              optionType: value,
                            }));
                          }}
                        >
                          <option value="all">All</option>
                          <option value="single">Single</option>
                          <option value="batch">Batch</option>
                        </select>
                      </div>
                    )}
                    {filters.viewType == 2 &&
                      filters.optionType == "single" && (
                        <div className="col-lg-2">
                          <select
                            className="form-control"
                            onChange={({ target: { value } }) => {
                              if (value) {
                                setFilter((prev) => ({
                                  ...prev,
                                  subOption: prev.viewType == 1 ? "" : value,
                                }));
                              }
                            }}
                          >
                            <option value="">Select User</option>
                            {userDetails?.map((item, index) => (
                              <option value={item.userrandomid} key={index}>
                                {item.username}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    {filters.viewType == 2 && filters.optionType == "batch" && (
                      <div className="col-lg-2">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setFilter((prev) => ({
                              ...prev,
                              subOption: e.target.value ? e.target.value : "",
                            }));
                          }}
                        >
                          <option value="all">All</option>
                          {BatchList.map((item, index) => (
                            <option value={item.batch_rank}>
                              {item.batch_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <DatePickerReuse2
                      setFilters={setFilter}
                      className={
                        filters.viewType == 2 ? "col-lg-3" : "col-lg-4"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="cmntbl mt-4">
                <div className="table-responsive">
                  {MeetingReport?.data?.length == 0 ? (
                    <div className="text-right">
                      <FontAwesomeIcon
                        icon={faDownload}
                        className=""
                        size="sm"
                      />{" "}
                      Export
                    </div>
                  ) : (
                    <div
                      className="text-right cursor-pointer"
                      onClick={() => exportFile()}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className=""
                        size="sm"
                      />{" "}
                      Export
                    </div>
                  )}
                  <DataTable
                    columns={columns}
                    className="text-center mt-3"
                    data={MeetingReport.data}
                    paginationTotalRows={MeetingReport.count}
                    customStyles={dataTableConfigs}
                    pagination={true}
                    paginationServer
                    onChangePage={(pageNo) => {
                      setFilter((prev) => ({
                        ...prev,
                        pageNo: pageNo - 1,
                      }));
                    }}
                    progressPending={Loading}
                    progressComponent={<TableLoader />}
                    noDataComponent={<NoDataComponent />}
                    onChangeRowsPerPage={(pageSize) => {
                      setFilter((prev) => ({ ...prev, pageSize }));
                    }}
                    persistTableHead={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MeetingReports;
