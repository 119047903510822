import React from 'react'
import Header from '../../component/header'
import Footer from '../../component/footer'
import ScrollToTop from '../../component/utils/useScrollToTop'
import metadata from '../../metaData/metadata'
import { Helmet } from 'react-helmet'
import NewHeader from '../../component/header/NewHeader'
import { useSearchParams } from 'react-router-dom'
const Privacypolicypage = () => {
	const [searchparams] = useSearchParams();
	const mobType = searchparams.get("type");
	return (
		<>
			{mobType !== "mobile" && <NewHeader />}
			<ScrollToTop />
			<Helmet>
				<title>{metadata.privacy.title}</title>
				<meta name='description' content={metadata.privacy.description} />
				<meta name='keywords' content={metadata.privacy.keywords} />
			</Helmet>
			<div class='maincontent leftrightCir'>
				<div class='pt-0'>
					<div class='box-primary'>
						<div class='container'>
							<div class='row'>
								<div class='col-12'>
									<div class='bt-yellow mb-1'>KPK PARK</div>
									<div class='secTit mb-0'>Privacy and Policy</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class='pb-5 mt-5'>
					<div class='container'>
						<div class='row'>
							<div class='col-12'>
								<div class='mycard'>
									<div class='cmspara'>
										<p>
											<p>
												This Privacy Policy outlines the utilization of your
												personal data when you engage with our website.
											</p>
											<p>
												At KPK Park, safeguarding your personal information and
												maintaining transparency regarding its usage is of
												utmost importance to us. We are dedicated to employing
												your personal information in compliance with relevant
												privacy laws, ensuring that you comprehend your data
												rights and freedoms.
											</p>
											<p>
												KPK Park assumes the role of "controller" for your data,
												taking charge of choosing the necessary personal data to
												be processed, and its intended purpose, and assuming
												responsibility for its secure storage and management.
											</p>
											<p>
												At first, you need to grasp the meaning of the term
												"Personal Data" (PD). It broadly encompasses any
												information about an individual that can directly or
												indirectly lead to their identification. Examples of
												such data include but are not limited to, full name,
												address, identification numbers, political or sexual
												orientation, email address, location details, and online
												identifiers like device information, IP address, or
												cookie data.
											</p>
											<p>
												To ensure a clear and comprehensive experience for you,
												we have implemented the necessary technical measures to
												provide you with a detailed agreement regarding the
												collection and use of personal data. It is important to
												note that the use of our website or services requires
												the provision of personal data, and by using our
												website, visitors and users agree and accept this
												requirement.
											</p>
											<p>
												For the Personal Data Protection Law (PDPL) and other
												applicable data protection laws within UAE, KPK Park
												s.l.l.c., a company registered and organized under the
												Laws of Croatia, Dubrovnik (Grad Dubrovnik), Rijecka
												12A, acts as the controller in our relationship with you
												through the website.
											</p>
											<p>
												Please be aware that this Privacy Policy does not extend
												to the websites of other companies or organizations that
												may be linked to or from our website. We advise you to
												carefully review the privacy policies of those sites to
												understand how they handle your personal information.
											</p>
											<p>
												If you do not agree with this Privacy Policy, we kindly
												ask you to refrain from accessing or using our website.
											</p>
											<p>
												<strong className="text-white">Definitions</strong>
											</p>
											<p>
												Our data protection policy should be legible and
												understandable for the general public, as well as for
												our users and business partners. To ensure this, we
												would like to first explain the terminology used. In
												this data protection declaration, we use, inter alia,
												the following terms:
											</p>
											<p>
												Data Subject or Data Principal - is any individual who
												is identified or identifiable, and whose personal data
												is collected and processed by us.
											</p>
											<p>
												Processing -is any operation performed on personal data,
												including but not limited to collection, recording,
												organization, structuring, storage, etc.
											</p>
											<p>
												Restriction of processing - is marking stored personal
												data to limit their future processing.
											</p>
											<p>
												Profiling - means any form of automated processing of
												personal data that assesses specific personal aspects
												related to an individual.
											</p>
											<p>
												Data Controller or Data Fiduciary - is the individual or
												organization, whether natural or legal, public
												authority, agency, or any other entity that determines
												the objectives and methods of processing personal data.
											</p>
											<p>
												Processor - is a natural or legal person, public
												authority, agency, or any other entity that handles
												personal data on behalf of the data controller.
											</p>
											<p>
												Recipient - is the natural or legal person, public
												authority, agency, or other entity to whom the personal
												data is disclosed. The processing of such data by these
												public authorities must adhere to applicable data
												protection regulations based on the purposes of the
												processing.
											</p>
											<p>
												Third-party - is a natural or legal person, public
												authority, body, or agency other than those mentioned
												above, who, under the direct authority of the data
												controller or processor, is authorized to process
												personal data.
											</p>
											<p>
												Consent - is any freely given, specific, informed, and
												unambiguous indication of acceptance by a data subject
												for the processing of their personal data.
											</p>
											<p>
												<strong className="text-white">Compliance</strong>
											</p>
											<p>
												The processing of personal data adheres to the following
												major international legislation:
											</p>
											<p>
												Personal Data Protection Law (PDPL), applicable in UAE
											</p>
											<p>
												Federal Law No. 15 of 2020 and Privacy Act U.S.C. 552a
												(Privacy Act of USA)
											</p>
											<p>
												We offer our services globally. In order to avoid any
												conflicts with compliance terminology from specific
												legislations, the terms used in this document are as
												follows: "Users" refer to "data subjects" or "data
												principals," while KPK Park is referred to as the "data
												controller" or "data fiduciary."
											</p>
											<p>
												Our privacy notice provides information regarding the
												collection, protection, sharing, access, modification,
												and limitation of personal data (PD) and non-personal
												data (NPD) that may be obtained from you. It also
												outlines your legal rights concerning your personal
												data. Any capitalized terms not defined in this document
												will carry the same meaning as defined elsewhere on our
												website.
											</p>
											<p>Automated Individual Profiling and Decision-Making</p>
											<p>
												You possess the right to avoid being subjected to a
												decision that is solely based on automated processing,
												including profiling, which has legal consequences for
												you or significantly similarly impacts you.
											</p>
											<p>Filing a complaint with authorities</p>
											<p>
												You retain the right to complain with supervisory
												authorities if your information has been processed in
												violation of any relevant data protection laws. If the
												supervisory authorities fail to address your complaint
												adequately, you may have the right to seek a judicial
												remedy.
											</p>
											<p>
												<strong className="text-white">Use Cases</strong>
											</p>
											<p>
												<strong>
													Please take a moment to familiarize yourself with the
													specific use case(s) outlined in this Privacy Policy
													that is relevant to you:
												</strong>
											</p>
											<p>
												<strong className="text-white">Website Visitor</strong>
											</p>
											<p>
												<strong>
													When you visit our website
													https://cuprumcoin.com/&nbsp;and any associated
													subdomains, you fall under the category of "Website
													Visitor." As a website visitor, we utilize your
													navigational statistical information for our own
													purposes, primarily to enhance the user experience of
													our website and provide you with more relevant
													content.
												</strong>
											</p>
											<p>
												<strong className="text-white">User</strong>
											</p>
											<p>
												<strong>
													By creating an account on our website/platform to
													avail yourself of our services, you become a "User."
													As a User, our primary objective in using your
													personal data is to provide you with the service and
													services we offer. We retain your personal information
													for a limited period and specific purposes, such as
													facilitating your future re-engagement with our
													service or presenting you with offers for services
													that we believe may interest you.
												</strong>
											</p>
											<p>
												<strong className="text-white">What data do we collect?</strong>
											</p>
											<p>
												When you acquire our services, we will request certain
												data from you. This includes your full name and a valid
												email address, which will be added to our mailing list
												for future updates, maintenance, and potential marketing
												promotions. Additionally, we will ask you to provide
												secure payment data, which will be processed by our
												payment processor in compliance with legal requirements
												and will not be stored by us.
											</p>
											<p>
												We may also process usage data, which pertains to your
												interactions with our website and services, or the
												website of the user. The usage data we collect may
												encompass various details, such as your IP address,
												geographical location, operating system, browser type
												and version, referral source, duration of visit, page
												views, website navigation paths, and information about
												the timing, frequency, and patterns of your service
												usage. Our analytics tracking system serves as the
												source of this usage data, and it may be analyzed to
												understand the utilization of our website and services.
											</p>
											<p>
												Service data provided by you during the use of our
												services may also be processed. This service data is
												used to deliver our services, ensure the security of our
												website and services, create backups of our databases,
												and facilitate communication with you. Also, any
												inquiries you submit to us about our services may be
												processed as inquiry data. We utilize this data to
												deliver our services, ensure the security of our website
												and services, generate backups of our databases, and
												facilitate communication with you.
											</p>
											<p>
												We may process information contained in any inquiry you
												submit to us regarding services and/or services
												("inquiry data"). The inquiry data may be processed for
												the purposes of providing our services, ensuring the
												security of our website and services, maintaining
												back-ups of our databases and communicating with you.
											</p>
											<p>
												In the context of our user relationships, we may process
												user relationship data, which includes your contact
												information, employer details, job title or role, and
												information exchanged between us and you or your
												employer. This data is processed to deliver our
												services, maintain the security of our website and
												services, create backups of our databases, and
												facilitate communication with you.
											</p>
											<p>
												If you engage in transactions, such as purchasing our
												services, we may process transaction data, which
												includes your contact details and the details of the
												transaction. The processing of this data is carried out
												to deliver our services, ensure the security of our
												website and services, establish backups of our
												databases, and facilitate communication with you.
											</p>
											<p>
												Lastly, any communications you send to us will involve
												the processing of correspondence data. This includes the
												content of the communication and associated metadata.
												The metadata is generated by our website's contact
												forms. Correspondence data is processed to deliver our
												services, maintain the security of our website and
												services, create backups of our databases, and
												facilitate communication with you.
											</p>
											<p>
												“Automatically Collected" Information: The website
												gathers a range of general data and information whenever
												it is accessed by a data subject or automated system.
												This data is automatically stored in server log files,
												irrespective of your quality:
											</p>
											<p>
												<strong className="text-white">Website Visitor or User.</strong>
											</p>
											<p>
												<strong className="text-white">Collected may be</strong>
											</p>
											<p>
												<strong className="text-white">Internet Service Provider (ISP)</strong>
											</p>
											<p>
												<strong className="text-white">
													Operating system used by the accessing system
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Website from which an accessing system reaches our
													website (referrers)
												</strong>
											</p>
											<p>
												<strong className="text-white">Sub-website accessed</strong>
											</p>
											<p>
												<strong className="text-white">Date and term of website access</strong>
											</p>
											<p>
												<strong className="text-white">Internet Protocol (IP) address</strong>
											</p>
											<p>
												<strong className="text-white">Screen resolution</strong>
											</p>
											<p>
												<strong className="text-white">Locale preferences</strong>
											</p>
											<p>
												<strong className="text-white">
													Webpage visited before arriving at our website
												</strong>
											</p>
											<p>
												<strong className="text-white">Search queries made on our website</strong>
											</p>
											<p>
												<strong className="text-white">
													Date and duration stamps associated with transactions
												</strong>
											</p>
											<p>
												<strong className="text-white">
													System configuration information and other
													interactions with the website
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Social networking information (if access to your
													social network account is provided)
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Any other similar data and information that may be
													used in the event of cybersecurity attacks on our IT
													systems.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													When using these general data and information, we do
													not draw any conclusions about the data subject.
													Rather, this information is needed to:
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Ensuring accurate delivery of website content
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Optimizing website content and advertisements
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Safeguarding the long-term functionality of our
													information technology systems and website technology
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Assisting law enforcement authorities with the
													information needed for criminal prosecution in the
													event of a cyber-attack
												</strong>
											</p>
											<p>
												<strong className="text-white">
													In addition to the specific purposes stated in this
													section for processing your personal data, we may also
													process your personal data when required to comply
													with a legal obligation or to protect your vital
													interests or those of another individual.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Please refrain from providing us with personal data
													belonging to others unless explicitly requested.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Please note that while access to our website is free
													for visitors, if you access the website through a
													mobile device, standard charges and tariffs as
													stipulated in your service contract with your mobile
													operator will still apply.
												</strong>
											</p>
											<p>
												<strong className="text-white">How do we collect your data?</strong>
											</p>
											<p>
												<strong className="text-white">
													The majority of the data collected by KPK Park is
													directly provided by you. We collect and process data
													when you avail yourself of our products and services.
												</strong>
											</p>
											<p>
												<strong className="text-white">What is the purpose of collection?</strong>
											</p>
											<p>
												<strong className="text-white">
													Our primary commercial purpose is to provide
													cryptocurrency services, financial reports, and other
													complementary services in accordance with the new
													regulations on personal data protection (such as PDPL,
													CCPA, etc.).
												</strong>
											</p>
											<p>
												<strong className="text-white">Additional purposes</strong>
											</p>
											<p>
												<strong className="text-white">To improve our services.</strong>
											</p>
											<p>
												<strong className="text-white">
													We constantly strive to provide you with the best
													online experience, and to achieve this, we may collect
													and utilize certain information about your behavior
													and preferences when using our website and platform.
													We may also conduct market research directly or in
													collaboration with our partners.
												</strong>
											</p>
											<p>
												<strong className="text-white">To improve your marketing activity</strong>
											</p>
											<p>
												<strong className="text-white">
													We may desire to keep you informed about the best
													offers related to the services you are interested in.
													As such, we may send you various types of messages
													(e.g., email, SMS, phone calls, mobile push
													notifications, web push notifications, etc.)
													containing general and thematic information about
													products or services, details about offers or
													promotions, and other commercial communications.
												</strong>
											</p>
											<p>
												<strong className="text-white">To defend our legitimate interests</strong>
											</p>
											<p>
												<strong className="text-white">Protecting our legitimate interests</strong>
											</p>
											<p>
												<strong className="text-white">
													There might be circumstances where we utilize or
													disclose information to safeguard our rights and
													ensure the continuity of our commercial operations.
													These situations may encompass:
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Implementing measures to safeguard website and
													platform visitors and users against cyber-attacks.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Taking preventive and detective actions to counteract
													fraudulent attempts.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Sharing information with competent public authorities
													when necessary.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Employing additional risk management measures to
													mitigate potential threats.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Hence, we guarantee you of the subsequent commitments:
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Safeguarding the privacy of your data: We prioritize
													the protection of your data and it is of utmost
													importance to our company's management.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Utilizing the data solely for personalizing your
													experience: We will use this data exclusively to
													enhance your personalized experience on our website
													and other online platforms where we promote our
													products and services (such as Facebook and Google).
												</strong>
											</p>
											<p>
												<strong className="text-white">How is your data stored?</strong>
											</p>
											<p>
												<strong className="text-white">
													KPK Park ensures the secure storage of your data on a
													dedicated server hosted by a third party located in
													the European Union.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													We do not utilize the information you provide to make
													any automated decisions that may impact you.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The Legal Basis for Collecting and Processing Personal
													Data
												</strong>
											</p>
											<p>
												<strong className="text-white">
													When you purchase or request our services, the
													collection and processing of your personal data are
													based on the necessity to fulfill a contract or take
													steps to enter into a contract.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Consequences of Not Providing Your Personal Data
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Failure to provide sufficient personal data may result
													in our inability to offer you our services. However,
													certain parts of our website (excluding our platform)
													can still be accessed and used without providing your
													personal data.
												</strong>
											</p>
											<p>
												<strong className="text-white">Your data protection rights?</strong>
											</p>
											<p>
												<strong className="text-white">
													KPK Park aims to ensure that you are fully informed.
													As a user, you are entitled to the following rights
													regarding your personal data:
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to be informed: You have the right to
													receive information about the personal data we collect
													from you and how we process it.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to access: You have the entitlement to
													obtain your personal data copies from KPK Park. Kindly
													be aware that there maybe a small fee associated with
													this service.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to rectify: You are entitled to ask KPK Park
													to rectify any inaccurate information and fill in any
													incomplete information that you believe is present.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to be forgotten: Subject to specific
													conditions, you have the right to ask KPK Park to
													delete your personal data.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to restrict processing: In specific
													circumstances, you can ask KPK Park to restrict the
													processing of your personal data.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to object to processing: You have the right
													to object to the processing of your personal data by
													KPK Park.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The right to data portability:You possess the right to
													express your objection to KPK Park's processing of
													your personal data.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													We are obligated to provide a response within a month
													upon receiving your request. If you would like to
													exercise any of these rights, please contact us.
												</strong>
											</p>
											<p>
												<strong className="text-white">Email us at: support@cuprumcoin.com</strong>
											</p>
											<p>
												<strong className="text-white">
													Send a message (WhatsApp) at: +385916044329
												</strong>
											</p>
											<p>
												<strong className="text-white">Privacy Policies of External Websites:</strong>
											</p>
											<p>
												<strong className="text-white">
													The KPK Park website/platform provides links to other
													websites. It's important to understand that our
													privacy policy exclusively applies to our own website.
													If you decide to click on a link redirecting to
													another website, we recommend reading their respective
													privacy policy.
												</strong>
											</p>
											<p>
												<strong className="text-white">Updates to Our Privacy Policy:</strong>
											</p>
											<p>
												<strong className="text-white">
													KPK Park routinely reviews its privacy policy and
													implements any necessary updates on this webpage. The
													updates will be notified in advance.
												</strong>
											</p>
											<p>
												<strong className="text-white">How to contact us</strong>
											</p>
											<p>
												<strong className="text-white">
													If you have any questions about KPK Park’s privacy
													policy, the data we hold on you, or if you like to
													exercise one of your data protection rights, please do
													not hesitate to contact us.
												</strong>
											</p>
											<p>
												<strong className="text-white">Email us at: support@cuprumcoin.com</strong>
											</p>
											<p>
												<strong className="text-white">
													Send a message (WhatsApp) at: +385916044329
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Sharing Information with Affiliates and Other Third
													Parties
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Our company does not sell or rent your personally
													identifiable information (PD) to third parties for
													marketing purposes. You understand and agree that we
													only share your PD with corporate stakeholders who are
													using our platform. However, we may use your non-PD
													for data aggregation, which may be sold to other
													parties at our discretion. It is important to
													emphasize that any aggregated data will not contain
													any of your personally identifiable information.
													Furthermore, your personally identifiable information
													(PD) may be shared with third-party service providers
													contracted by us to provide services on our behalf.
													These service providers encompass various entities
													such as payment processors, data management services,
													web analytics firms, advertising networks, help desk
													providers, call centers, auditors, accountants, law
													firms, shopping cart, and email service providers, and
													shipping companies, among others.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Managing Your Personal Data: Retention and Deletion
												</strong>
											</p>
											<p>
												<strong className='text-white'>
													The personal information obtained from users is stored
													for as long as their account remains active on our
													platform. Alternatively, we retain the data for a
													limited duration necessary to fulfill the original
													purposes for its collection, unless legally obligated
													to do otherwise.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													In situations where we cannot determine the exact
													duration for retaining your personal data in advance,
													we apply a two-year criterion after your association
													with us concludes.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													There are instances where we may need to retain your
													personal data to fulfill legal obligations or
													safeguard your vital interests, as well as those of
													others.
												</strong>
											</p>
											<p>
												<strong className="text-white">
													Transferring PD From the European Economic Area
												</strong>
											</p>
											<p>
												<strong className="text-white">
													The personal data (PD) that we gather from you may be
													stored, processed, and moved among the various
													countries where our operations are conducted. The
													European Union and the UK have determined that the
													United States and certain other countries do not
													provide an adequate level of PD protection under
													Article&nbsp;45 of the Personal Data Protection Law
													(PDPL). However, our company relies on specific
													circumstances that warrant exceptions, as outlined in
													Article 49 of the PDPL. In the case of European Union
													customers and users, your personal data may be
													transmitted to countries outside the European Union,
													such as the United States, if you provide your
													consent. This is done to fulfill your requested
													services or provide you with the information you seek,
													as part of our contractual obligation or legitimate
													interest, as long as it does not outweigh your
													freedoms and rights. We will implement appropriate
													precautions to protect your personal data (PD) when it
													is being transferred, processed, or stored. By using
													our website, services, or products, you indicate your
													consent to the PD transfers detailed in this section.
													The data collected from you will be managed in
													compliance with our privacy notice.
												</strong>
											</p>
											<p>
												<strong className="text-white">Privacy Policy Updates</strong>
											</p>
											<p>
												We retain the right to modify this privacy policy at any
												given time. If our company opts to make changes, we will
												publish them on our website to ensure that our users and
												customers are always informed about the data we gather,
												how we utilize it, and how we share it.In the event that
												we choose to utilize or reveal your personally
												identifiable information (PII) in a way that differs
												from the originally stated purpose of collection, we
												will notify you beforehand via email (sent to the email
												address linked to your account).
											</p>
											<p>
												Otherwise, we will handle and disclose the PII of our
												users and customers in accordance with the privacy
												policy that was in place when the information was
												initially obtained. Irrespective of the circumstances,
												your continued use of our website, services, and
												products following any modifications to this privacy
												policy will signify your acceptance of said
												changes.&nbsp;
											</p>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			{mobType !== "mobile" && <Footer />}
		</>
	)
}

export default Privacypolicypage
