import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { ScreenLoader, SpinnerLoader } from "../../component/Loaders";
import CloseBtn from "../../component/Reusable/CloseBtn";
import Helper from "../../Helper/Helper";
import BatchReports from "../BatchReports";
import Eventcalandar from "./CrmEventCalendar/Eventcalandar";
import GoalsHistoryNew from "./GoalsAchiever/goalHistoryNew";
import LeadList from "./Leads/LeadList";
import BopMeetingHistory from "./Meeting/BopMeetingHistory";
import TodoHistory from "./Todo";
import MeetingReports from "./Reports/ReportsNavIndex";
import ReportsNavIndex from "./Reports/ReportsNavIndex";
const CRMDashboard = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const [searchParams] = useSearchParams();
  const active_tab = searchParams.get("activeTab");
  const [activeTab, setActiveTab] = useState(active_tab || "1");
  useEffect(() => { }, [active_tab]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [modalTeam, setModalTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleTeam = () => {
    setModalTeam(!modalTeam);
  };
  const toggleTeamClose = () => {
    setModalTeam(false);
    reset();
  };
  useEffect(() => {
    GetTeamName();
  }, []);
  const [defaultTeam, setDefaultTeam] = useState("");
  const GetTeamName = () => {
    Helper.GetData("flow-new", "user_crm/get_team_name", false).then((res) => {
      if (res.status) {
        console.log("resssssss", res);
        setDefaultTeam(res.data);
      }
    });
  };
  const HandleTeamConfirm = (data) => {
    setLoading(true);
    Helper.PostData("flow-new", "user_crm/edit_team_name ", data, true)
      .then((res) => {
        if (res.status) {
          reset();
          toggleTeamClose();
          setLoading(false);
          GetTeamName();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="dashboard-main">
        <ScreenLoader>
          <div className="container dash-container">
            <div className="row">
              <div className="col-6">
                <div className=" mb-3">
                  <div className="">
                    <div className="">
                      <h5 className="dashboard-headTxt m-0 mb-3 mb-lg-0">
                        CRM Dashboard
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-6">
                <div className=" mb-3">
                  <div className="">
                    <div className="text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => toggleTeam()}
                      >
                        Team
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <section className="dashpro-sec">
            <div className="container dash-container">
              <div className="row">
                <div className="col-12"></div>
              </div>
            </div>
          </section>

          <section className="newbonus-sec">
            <div className="container dash-container">
              <div className="dashboard-maincnt transdet-sec">
                <div className="newdashboard-grid">
                  <div className="dashboard-sidetabul">
                    <div className="mypills">
                      <Nav pills>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Dashboard
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Leads
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            Bop Meeting
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggle("4");
                            }}
                          >
                            Todo
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              toggle("5");
                            }}
                          >
                            Goals
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "6",
                            })}
                            onClick={() => {
                              toggle("6");
                            }}
                          >
                            Batch Reports
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "7",
                            })}
                            onClick={() => {
                              toggle("7");
                            }}
                          >
                            Reports
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={"1"}>
                          {activeTab == "1" && <Eventcalandar />}
                        </TabPane>
                        <TabPane tabId={"2"}>
                          {activeTab == "2" && <LeadList />}
                        </TabPane>
                        <TabPane tabId={"3"}>
                          {activeTab == "3" && <BopMeetingHistory />}
                        </TabPane>
                        <TabPane tabId={"4"}>
                          {activeTab == "4" && <TodoHistory />}
                        </TabPane>
                        <TabPane tabId={"5"}>
                          {/* {activeTab == "5" && <GoalsHistory />} */}
                          {activeTab == "5" && <GoalsHistoryNew />}
                        </TabPane>
                        {/* <TabPane tabId={"6"}>
                          {activeTab == "6" && <BatchReports />}
                        </TabPane> */}
                        <TabPane tabId={"7"}>
                          {activeTab == "7" && <ReportsNavIndex />}
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ScreenLoader>
      </div>
      <Modal
        isOpen={modalTeam}
        toggle={toggleTeam}
        className=""
        size="sm"
        centered
      >
        <ModalHeader
          toggle={toggleTeamClose}
          close={<CloseBtn onClick={() => toggleTeamClose()} />}
        >
          Add Team Name
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <Form
                onSubmit={handleSubmit(HandleTeamConfirm)}
                autoComplete="off"
              >
                <div className="form-group formInputs walletInput">
                  <label className="col-form-label">
                    Team Name
                    <span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    name="team_name"
                    className="form-control"
                    defaultValue={defaultTeam}
                    placeholder="Enter Team Name"
                    {...register("team_name", {
                      required: "* Team Name is required",
                    })}
                  />
                  {errors.team_name && (
                    <p className="text-danger">{errors.team_name.message}</p>
                  )}
                </div>
                <div className="submit-btn text-center">
                  {(() => {
                    if (loading) {
                      return (
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled={loading}
                        >
                          <SpinnerLoader />
                        </button>
                      );
                    } else {
                      return (
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      );
                    }
                  })()}
                </div>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CRMDashboard;
