import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../../Helper/Helper";
import { TableLoader } from "../../../component/Loaders";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import SearchReuse2 from "../../../component/Reusable/searchReuse2";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
import DatePicker from "react-datepicker";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const GoalReports = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [Loading, setLoading] = useState(false);
  const [MeetingReport, setMeetinReport] = useState({
    data: [],
    count: 0,
  });
  console.log("MeetingReport: ", MeetingReport);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fromMinDate = () => {
    let date = new Date();
    date.setFullYear(2023);
    date.setDate(1);
    date.setMonth(0);
    return date;
  };
  const [filters, setFilter] = useState({
    search_by: "",
    status: "all",
    ...initialPageData,
  });

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("", "goal/getMyReferGoalList", filters, false)
      .then((res) => {
        if (res.status) {
          setMeetinReport({
            data: res.results,
            count: res.count,
          });
        } else {
          setMeetinReport({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      width: "100px",
    },
    {
      name: "Goal User Name",
      selector: (row, index) => row.goalUser,
      sortable: true,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.goalUser}</td>
      ),
    },
    {
      name: "Acheived Goal Count",
      sortable: true,
      selector: (row, index) => row.acheivedGoals,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.acheivedGoals}</td>
      ),
    },
    {
      name: "Target Goal Count",
      sortable: true,
      selector: (row, index) => row.targetGoals,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.targetGoals}</td>
      ),
    },
  ];
  const HandleDownload = async () => {
    Helper.excelReport(
      "goal/getMyReferGoalListDwn",
      "",
      filters,
      false,
      "goalReport.xlsx"
    );
  };
  return (
    <Fragment>
      <div className="trans-body mt-5">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Body">
              <div className="tbl-header-items" style={{ display: "block" }}>
                <div className="showentries-LeftCnt">
                  <div className="row inr-cnt">
                    <div className="col-lg-3">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            status: e.target.value,
                          }))
                        }
                      >
                        <option value="all">All</option>
                        <option value={1}>Completed</option>
                        <option value={0}>InComplete</option>
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <div class="wallet-in-ser ">
                        <div class="input-login-1  ml-lg-2">
                          <DatePicker
                            dateFormat="M,yyyy"
                            className="date-input"
                            placeholderText="Select Month"
                            selected={fromDate}
                            // minDate={new Date()}
                            // maxDate={new Date()}
                            showMonthYearPicker
                            onChange={(date) => {
                              if (date) {
                                setFilter((prev) => ({
                                  ...prev,
                                  goal_month: moment(date).format("M,YYYY"),
                                }));
                                setFromDate(date);
                              } else {
                                setFromDate("");
                                setToDate("");
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <SearchReuse2
                      className={"col-lg-3"}
                      placeholder={"Search By Goal User"}
                      setFilters={setFilter}
                      initialPageData={initialPageData}
                      filterKey="search_by"
                      type="goal"
                    />
                    {MeetingReport?.data?.length == 0 ? (
                      <div className="col-lg-3 ">
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </div>
                    ) : (
                      <div
                        className="col-lg-3 cursor-pointer"
                        onClick={() => HandleDownload()}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="cmntbl mt-4">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    className="text-center mt-3"
                    data={MeetingReport.data}
                    paginationTotalRows={MeetingReport.count}
                    customStyles={dataTableConfigs}
                    pagination={true}
                    paginationServer
                    onChangePage={(pageNo) => {
                      setFilter((prev) => ({
                        ...prev,
                        pageNo: pageNo - 1,
                      }));
                    }}
                    progressPending={Loading}
                    progressComponent={<TableLoader />}
                    noDataComponent={<NoDataComponent />}
                    onChangeRowsPerPage={(pageSize) => {
                      setFilter((prev) => ({ ...prev, pageSize }));
                    }}
                    persistTableHead={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GoalReports;
